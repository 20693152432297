import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[hasScrollbar]',
    standalone: true,
})
export class HasScrollbarDirective implements AfterViewInit {

    @Input('hasScrollbar') element!: HTMLElement;
    @Input() scrollbarClass: string[] | string = [];

    constructor(private host: ElementRef) { }

    ngAfterViewInit() {
        const observer = new ResizeObserver((mutations, observer) => {
            mutations.forEach(_mutation => {
                this.checkScrollbar();
            })
        })

        observer.observe(this.element);

    }

    private checkScrollbar() {
        if (this.hasScrollBar()) {
            this.host.nativeElement.classList.add(this.scrollbarClass);
        } else {
            this.host.nativeElement.classList.remove(this.scrollbarClass);
        }
    }

    private hasScrollBar() {
        return this.element.scrollHeight > this.element.clientHeight;
    }
}
