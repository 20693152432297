import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {

    constructor(
        private toastController: ToastController,
        private translateService: TranslateService
    ) {
    }

    async notify(msg: string, color: 'success' | 'warning' | 'danger' | 'primary', position: 'top' | 'bottom' | 'middle' = 'bottom', duration: number = 3000, params?: {}) {

        let icon: string;
        switch (color) {
            case "success": {
                icon = "circle-check";
                color = 'success';
                break;
            }
            case "warning": {
                icon = "circle-exclamation";
                color = 'warning';
                break;
            }
            case "danger": {
                icon = "triangle-exclamation";
                color = 'danger';
                break;
            }
            default: {
                icon = "close";
                color = 'primary';
                break;
            }
        }

        let message: string;

        // Using await stream to avoid not-yet-loaded translations from being shown
        if (params) {
            message = await firstValueFrom(this.translateService.stream(msg, params))
        } else {
            message = await firstValueFrom(this.translateService.stream(msg))
        }

        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            translucent: true,
            position: position,
            color: color,
            mode: 'ios',
            cssClass: 'toast-vertical-adjust',
            buttons: [
                {
                    side: 'start',
                    // icon: icon,
                }, {
                    icon: 'close',
                    role: 'cancel'
                }
            ]
        });

        if (await this.toastController.getTop()){
          await this.toastController.dismiss()
        }

        await toast.present();
    }

    async customNotify(message: string, color: string, position: 'top' | 'bottom' | 'middle' = 'bottom', duration: number = 2000) {

        let icon: string;
        switch (color) {
            case "success": {
                icon = "circle-check";
                color = 'success';
                break;
            }
            case "warning": {
                icon = "circle-exclamation";
                color = 'warning';
                break;
            }
            case "danger": {
                icon = "triangle-exclamation";
                color = 'danger';
                break;
            }
            default: {
                icon = "close";
                color = 'primary';
                break;
            }
        }

        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            translucent: true,
            position: position,
            color: color,
            mode: 'ios',
            cssClass: 'toast-vertical-adjust',
            buttons: [
                {
                    side: 'start',
                    icon: icon,
                }, {
                    icon: 'close',
                    role: 'cancel'
                }
            ]
        });

        await toast.present();
    }

}
