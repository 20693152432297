import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ToastComponent, ToastConfig } from '../toast.component';
import { ToastService } from '../toast.service';
import { BehaviorSubject, filter, firstValueFrom, Subject } from 'rxjs';

@Component({
    selector: 'csm-toast-container',
    templateUrl: './toast-container.component.html',
    styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent implements OnInit, AfterViewInit {

    private _hasInit$ = new BehaviorSubject<boolean>(false);
    private hasInit$ = this._hasInit$.pipe(filter(value => value));
    @ViewChildren('toastComponent') toastComponents!: QueryList<ToastComponent>;

    @Input() position: 'top' | 'bottom' = 'bottom';
    @Input() toasts: ToastConfig[] = [];

    constructor(private toastService: ToastService) {
    }

    ngOnInit() {
    }

    public ngAfterViewInit() {
        this._hasInit$.next(true);
    }

    protected removeToast(toast: ToastConfig) {
        this.toasts.splice(this.toasts.indexOf(toast), 1);
    }

    public async getToastComponent(toast: ToastConfig): Promise<ToastComponent> {
        await firstValueFrom(this.hasInit$);
        console.log(`find toast at index ${this.toasts.indexOf(toast)}`, this.toastComponents.toArray());
        return this.toastComponents.get(this.toasts.indexOf(toast))!;
    }
}
