import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ToastComponent } from './toast.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastDirective } from './toast.directive';
import { ToastContainerComponent } from './toast-container/toast-container.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FontAwesomeModule
    ],
    declarations: [
        ToastContainerComponent,
        ToastComponent,
        ToastDirective
    ],
    exports: [
        ToastContainerComponent,
        ToastComponent,
        ToastDirective
    ]
})
export class ToastModule {
}
