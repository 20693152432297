export const ROUTES_API = {
	'token': 'token/',
    "campagne": "portaleweb/web_campagne/",
    "partner": "portaleweb/web_business_partner/",
    "ordini": "portaleweb/web_flusso_ordini/",
    "ordini-crea": "portaleweb/web_ordini/",
    "ordini-aggiungi-prodotto": "portaleweb/web_righeordine/",
    "prodotti": "portaleweb/web_dettaglio_prodotti/",
    "destinatario": "portaleweb/web_destinations/"
} as const;
