import { AfterContentInit, AfterViewInit, Directive, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, Subject } from 'rxjs';

@Directive({
    selector: '[ngLifecycle]',
    standalone: true,
})
export class LifecycleDirective implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    private _unsubscribe$ = new Subject<void>();
    public unsubscribe$ = this._unsubscribe$.asObservable();

    public hasInit = false;
    private _onInit$ = new BehaviorSubject<boolean>(false);
    public onInit() { return firstValueFrom(this._onInit$.pipe(filter(value => value))) }
    public onInit$ = this._onInit$.asObservable();

    public hasViewInit = false;
    private _afterViewInit$ = new BehaviorSubject<boolean>(false);
    public afterViewInit() { return firstValueFrom(this._afterViewInit$.pipe(filter(value => value))) }
    private afterViewInit$ = this._afterViewInit$.asObservable();

    public hasContentInit = false;
    private _afterContentInit$ = new BehaviorSubject<boolean>(false);
    public afterContentInit() { return firstValueFrom(this._afterContentInit$.pipe(filter(value => value))) }
    private afterContentInit$ = this._afterContentInit$.asObservable();

    public hasDestroy = false;
    private _onDestroy$ = new BehaviorSubject<boolean>(false);
    public onDestroy() { return firstValueFrom(this._onDestroy$.pipe(filter(value => value))) }
    private onDestroy$ = this._onDestroy$.asObservable();

    constructor() {}

    public ngOnInit() {
        this.hasInit = true;
        this._onInit$.next(true);
    }

    public ngAfterViewInit() {
        this.hasViewInit = true;
        this._afterViewInit$.next(true);
    }

    public ngAfterContentInit() {
        this.hasContentInit = true;
        this._afterContentInit$.next(true);
    }

    public ngOnDestroy() {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this.hasDestroy = true;
        this._onInit$.next(true);
    }
}
