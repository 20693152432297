import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { CommunicationService } from "../services/communication.service";

@Injectable({providedIn: 'root'})
export class AuthGuard {

    constructor(
        private auth: AuthService,
        private communication: CommunicationService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
        if (await this.auth.isUserLoggedIn()) {
            return true;
        } else {
            this.auth.logout();
            await this.communication.notify('COMMUNICATION.LOGIN.NOT_LOGGED_IN', 'danger')
            return false;
        }
    }
}
