import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Component({
    selector: 'csm-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private theme: ThemeService) {
        // this.theme.useBrowserDefault();
    }

    public async ngOnInit() {
    }
}
