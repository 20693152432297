import { AnimationController, Animation } from "@ionic/angular";

const animationCtrl = new AnimationController();

export const customAnimation = (_: HTMLElement, opts: any): Animation => {
    // create root transition
    const rootTransition = animationCtrl
        .create()
        .duration(opts.duration || 300)
        .easing('ease');

    const enterTransition = animationCtrl.create().addElement(opts.enteringEl);
    const exitTransition = animationCtrl.create().addElement(opts.leavingEl);

    enterTransition.fromTo('opacity', '0', '1');
    exitTransition.fromTo('opacity', '1', '0');

    if (opts.direction === 'back') {
        enterTransition.fromTo('transform', 'translateX(-3%)', 'translateX(0%)');
        exitTransition.fromTo('transform', 'translateX(0%)', 'translateX(3%)');
    } else {
        enterTransition.fromTo('transform', 'translateX(3%)', 'translateX(0%)');
        exitTransition.fromTo('transform', 'translateX(0%)', 'translateX(-3%)');
    }

    rootTransition.addAnimation([enterTransition, exitTransition]);
    return rootTransition;
}
