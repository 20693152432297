import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[kwLoading]',
    standalone: true,
})
export class KwLoadingDirective implements OnChanges {

    @Input('kwLoading') isLoading = false;

    constructor(private el: ElementRef,
                private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isLoading']) {
            if (this.isLoading) {
                this.showSpinner();
            } else {
                this.hideSpinner();
            }
        }
    }

    private showSpinner() {
        const backdrop = this.renderer.createElement('div');
        this.renderer.setStyle(backdrop, 'width', '100%');
        this.renderer.setStyle(backdrop, 'height', '100%');
        this.renderer.setStyle(backdrop, 'position', 'absolute');
        this.renderer.setStyle(backdrop, 'top', '0');
        this.renderer.setStyle(backdrop, 'left', '0');
        this.renderer.setStyle(backdrop, 'background', 'rgba(var(--ion-color-base-rgb), .7)');
        this.renderer.addClass(backdrop, 'kwLoading-backdrop')

        const spinner = this.renderer.createElement('ion-spinner');
        this.renderer.setAttribute(spinner, 'name', 'crescent');
        this.renderer.setStyle(spinner, 'position', 'absolute');
        this.renderer.setStyle(spinner, 'top', '50%');
        this.renderer.setStyle(spinner, 'left', '50%');
        this.renderer.setStyle(spinner, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(spinner, 'z-index', '2');
        this.renderer.addClass(spinner, 'kwLoading-spinner')

        this.renderer.addClass(this.el.nativeElement, 'kwLoading-active');
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
        this.renderer.appendChild(this.el.nativeElement, spinner);
        this.renderer.appendChild(this.el.nativeElement, backdrop);
    }

    private hideSpinner() {
        const spinner = this.el.nativeElement.querySelector('ion-spinner.kwLoading-spinner');
        const backdrop = this.el.nativeElement.querySelector('div.kwLoading-backdrop');
        if (spinner) {
            this.renderer.removeChild(this.el.nativeElement, spinner);
        }
        if (backdrop) {
            this.renderer.removeChild(this.el.nativeElement, backdrop);
        }

        this.renderer.removeClass(this.el.nativeElement, 'kwLoading-active');
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'auto');
    }

}
