import { Injectable } from '@angular/core';
import { ROUTES_API } from './routes-api';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GenerateApiRouteService {

  routes = ROUTES_API;
  endpoint = environment.apiUrl;


  constructor(private http: HttpClient) {
  }

  /**
   * @desc Genera l'url dell'API in base a cosa viene passato tra i paramtri
   * @param route Tipo di url da restituire
   * @param params Parametri da sostituire nell'url
   */
  public generateUrl(route: keyof typeof this.routes, params?: Record<string, any>): string {
    let actualRoute: string = this.routes[route];
    if (actualRoute) {
      if (params) {
        for (const param in params) {
          actualRoute = actualRoute.replace('{' + param + '}', params[param]);
        }
      }
    }

    return this.endpoint + actualRoute;
  }

  /*propsApiCall(){
    this.http.get(environment.apiUrl + 'wmsade/').pipe(first()).subscribe({
      next: () => {
        localStorage.setItem('endpoint', environment.apiUrl);
      },
      error: () => {
        localStorage.setItem('endpoint', environment.apiUrlExternal);
      }
    })
  }*/
}
