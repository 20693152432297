import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { wait } from '../../../utils/timing.util';
import { firstValueFrom } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastService } from './toast.service';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { IonColor } from '../../../directives/ion-color.directive';

@Component({
    selector: 'csm-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms 100ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ backgroundColor: 'red', bottom: 'calc(((var(--toast-total, 0)) - var(--toast-index)) * var(--toast-height))', }),
                animate('300ms 300ms', style({ opacity: 0 }))
            ])
        ])
    ],
    host: {
        '[class]': 'toastColor'
    }
})
export class ToastComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() @HostBinding('@fadeAnimation') animated = true;

    @Input() icon?: IconProp;
    @Input() duration: number = 0;
    @Input({ required: true }) message!: string;
    @Input() color: IonColor = 'dark';

    protected get toastColor() {
        return `ion-color-${this.color}`
    }

    @Output() didDismiss = new EventEmitter<void>();
    @Output() didPresent = new EventEmitter<void>();

    async onDidDismiss() {
        return firstValueFrom(this.didDismiss);
    }

    async onDidPresent() {
        return firstValueFrom(this.didPresent);
    }

    constructor(
        @Optional() private container: ToastContainerComponent,
        private toastService: ToastService) {
        if (!this.container) {
            throw new Error(`ToastComponent cannot be used outside of ToastContainerComponent`);
        }
    }

    ngOnInit() {
        if (this.duration) {
            wait(Math.abs(this.duration)).then(() => this.didDismiss.emit());
        }
    }

    public ngAfterViewInit() {
        this.didPresent.emit();
    }

    public ngOnDestroy() {
        console.log('destroy');
    }

    public async dismiss() {
        this.didDismiss.emit();
    }
}

export interface ToastConfig {
    message: string,
    duration?: number,
    animated?: boolean,
    color?: IonColor | string,
    icon?: IconProp
    close?: boolean
}
