import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { register } from "swiper/element/bundle";
import { registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it'

registerLocaleData(it)

register();
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
