import { Injectable } from '@angular/core';
import { request, SessionService, StorageService } from 'kwlibrary';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GenerateApiRouteService } from '../routing-api/generate-api-route.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user$: BehaviorSubject<AuthModel | undefined> = new BehaviorSubject<AuthModel | undefined>(this.getStoredUser());
    public get user$() {
        return this._user$.asObservable();
    }

    constructor(private http: HttpClient,
                private api: GenerateApiRouteService,
                private translate: TranslateService,
                private router: Router,
                private nav: NavController,
                private session: SessionService,
                private storage: StorageService) {
    }

    private getStoredUser() {
        return this.storage.get<AuthModel>('user') || undefined;
    }

    public getLoginRedirect(user: AuthModel): string {
        const redirect = this.session.get<string>(`login-redirect-${ user.username }`);
        return redirect || '';
    }

    public async login(username: string, password: string) {
        const post = this.http.post<{
            refresh: string,
            access: string
        }>(this.api.generateUrl('token'), { username, password });

        const [data, error] = await request<{ refresh: string, access: string }, HttpErrorResponse>(post);

        if (error && error.status !== 401) {
            throw error;
        }

        if (!data) {
            return null;
        }

        const user = this.decodeJWTToken(data.access);

        const userCSMData: AuthModel = {
            id: user['user_id'],
            username: username,
            token: data.access
        };

        this._user$.next(userCSMData);
        this.storage.set('user', userCSMData);

        return userCSMData;
    }

    public logout() {
        const user = this.storage.get<AuthModel>('user');
        if (user) {
            this.session.set(`login-redirect-${ user.username }`, this.router.url);
        }

        this._user$.next(undefined);
        this.storage.delete('user');
        this.storage.delete('campaign_id');
        this.storage.delete('partner_id');
        this.storage.delete('pending_order_new');

        this.nav.setDirection('back');
        void this.router.navigate(['/' + this.translate.instant('ROUTES.LOGIN')]);
    }

    private decodeJWTToken(token: string) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch {
            console.error(`Could not decode invalid token.`);
            return '';
        }
    }

    private isTokenValid(token: string) {
        const decoded = this.decodeJWTToken(token);
        const expiration = decoded ? decoded['exp'] * 1000 : 0;
        const valid = Date.now() <= expiration;
        if (valid) {
            const interval = (expiration - Date.now()) / 60000;
            console.log(`Token expires in ${ Math.floor(interval) } minutes`);
        } else {
            console.log(`Token is expired`);
        }
        return valid;
    }

    public async isUserLoggedIn() {
        const user = await firstValueFrom(this._user$);
        return !!user && this.isTokenValid(user.token);
    }
}

interface AuthModel {
    id: number,
    token: string,
    username: string
}
