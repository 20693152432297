import { effect, Injectable, signal } from '@angular/core';
import { StorageService } from 'kwlibrary';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private readonly _theme = signal<'dark' | 'light'>('light');
    public get theme() { return this._theme.asReadonly() }

    public isDarkMode() { return this.theme() === 'dark' }
    public isLightMode() { return this.theme() === 'light' }
    public enableDarkMode() {
        this._theme.set('dark');
        this.storage.set('theme', 'dark');
    };
    public enableLightMode() {
        this._theme.set('light');
        this.storage.set('theme', 'light');
    };

    constructor(private storage: StorageService) {
        this._theme.set(this.storage.get('theme') || 'light');

        effect(() => {
            this.applyBodyClass(this.theme() === 'dark')
        });
    }

    public useBrowserDefault() {
        this.storage.set('theme', null);
        this.themeListener();
    }

    private themeListener() {
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        this._theme.set(media.matches ? 'dark' : 'light');
        media.addEventListener('change', event => {
            this._theme.set(event.matches ? 'dark' : 'light');
        });
    }

    private applyBodyClass(dark: boolean) {
        if (dark) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }
}
