import { Directive, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Directive({
    selector: 'ion-app[toastRoot]'
})
export class ToastDirective {

    private static _viewRef$ = new BehaviorSubject<ViewContainerRef | null>(null);
    public static get viewRef$() { return this._viewRef$.pipe(filter(value => !!value)) as Observable<ViewContainerRef> }

    constructor(
        private view: ViewContainerRef,
    ) {
        ToastDirective._viewRef$.next(this.view);
    }
}
