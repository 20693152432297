/**
 * Resolves a promise after the specified delay. If a second parameter is provided, then the delay time is random between the two values.
 * @param ms The number of milliseconds until the promise is resolved.
 */
export async function wait(ms: number): Promise<void>
/**
 * Resolves a promise after random value from a specified time range.
 * @param min The min number of milliseconds.
 * @param max The max number of milliseconds.
 */
export async function wait(min: number, max: number): Promise<void>
export async function wait(min: number, max?: number): Promise<void>
{
    if (min < 0 || (max !== undefined && max < 0)) {
        throw new Error(`Wait time cannot be negative. Called wait(${min}${max !== undefined ? `, ${max}` : ``})`);
    }

    if (max && min > max) {
        [ min, max ] = [ max, min ];
    }

    let delay = min;

    if (max !== undefined) {
        delay = Math.floor(Math.random() * (max - min + 1) + min);
    }

    return new Promise<void>(resolve => { setTimeout(() => { resolve() }, delay )});
}
