import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { catchError, map, switchMap, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { Router } from '@angular/router';
import { StorageService } from 'kwlibrary';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
    const auth = inject(AuthService);
    const token$ = auth.user$.pipe(map(user => user?.token));

    // TODO: Fix sometimes repeats last request without token

    return token$.pipe(
        switchMap(token => {
            if (token) {
                const clone = request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${token}`)
                });

                return next(clone);
            } else {
                return next(request);
            }
        })
    )
};
