import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { Router } from '@angular/router';
import { StorageService } from 'kwlibrary';

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
    const storage = inject(StorageService);
    const router = inject(Router);
    const communication = inject(CommunicationService);

    return next(request).pipe(
        catchError((err: HttpErrorResponse) => {

            if (err.error.code === 'token_not_valid') {
                router.navigate(['/login']).then(() => {
                    storage.set('user', null);
                })
            }

            if (err.status === 0 && err.url === environment.apiUrl) {
                if (!storage.get('userEndpointWarned')){
                    communication.notify('COMMUNICATION.ENDPOINT.EXTERNAL', 'warning').then(()=> {
                        storage.set('userEndpointWarned', true)
                    });
                }
                return throwError(()=> {});

            } else {
                communication.customNotify(err.error.detail ? err.error.detail : 'Errore generico ' + err.status, 'danger').then()
            }


            return throwError(() => err);
        })
    );
};
