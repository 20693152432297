<div class="csm-toast-start" slot="start">
    <fa-icon *ngIf="icon"></fa-icon>
    <ng-content select="[slot=start]"></ng-content>
</div>
<div class="csm-toast-center">
    <ng-content></ng-content>
    {{ message }}
</div>
<div class="csm-toast-end" slot="end">
    <ng-content select="[slot=end]"></ng-content>
</div>
