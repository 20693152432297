import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ToastDirective } from './toast.directive';
import { firstValueFrom } from 'rxjs';
import { ToastComponent, ToastConfig } from './toast.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { IonColor } from '../../../directives/ion-color.directive';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    private toasts: ToastConfig[] = [];

    constructor() {
    }

    private containers: Record<string, ComponentRef<ToastContainerComponent>> = {};

    public async show(message: string, duration: number = 0, color: IonColor = 'primary') {
        const view = await firstValueFrom(ToastDirective.viewRef$);
        const container = await this.getContainer(view, 'bottom');

        const toast = {
            message,
            duration,
            color,
            close: true
        };

        this.toasts.push(toast);
        container.instance.toasts = this.toasts;

        if (toast.duration) {
            setTimeout(() => {
                this.toasts.splice(this.toasts.indexOf(toast), 1);
            }, duration)
        }

        return container.instance.getToastComponent(toast);
    }

    public async dismiss(toast: ToastComponent) {

    }

    private async getContainer(view: ViewContainerRef, position: 'top' | 'bottom') {
        if (this.containers[position]) {
            return this.containers[position];
        }

        this.containers[position] = view.createComponent(ToastContainerComponent);
        this.containers[position].instance.position = position;

        return this.containers[position]
    }

}
