import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';
import { HttpBackend, provideHttpClient, withInterceptors } from '@angular/common/http';
import { KW_SESSION_PREFIX, KW_STORAGE_PREFIX, StorageService } from 'kwlibrary';
import { KwLoadingDirective } from '../directives/kw-loading.directive';
import { customAnimation } from '../animations/router';
import { errorInterceptor } from '../interceptors/error.interceptor';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { ToastModule } from './components/toast/toast.module';
import { HasScrollbarDirective } from '../directives/has-scrollbar.directive';
import { authInterceptor } from '../interceptors/auth.interceptor';
import { LifecycleDirective } from '../directives/lifecycle.directive';
import { formatDate } from "@angular/common";

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [{ prefix: './assets/i18n/', suffix: '.json' }]);
}

/**
 * Adds the missing translation to a localStorage log for easier debugging.
 */
class CustomMissingTranslationHandler implements MissingTranslationHandler {
    private storage = new StorageService('csm_debug');
    public handle(params: MissingTranslationHandlerParams): any {
        const log = (this.storage.get('i18n-log') || {}) as Record<string, string>;

        if (!Object.values(log).includes(params.key)) {
            log[formatDate(new Date(), 'yyyy-MM-dd_HH:mm', 'it-IT')] = params.key;
            this.storage.set('i18n-log', log);
        }

        return null;
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            navAnimation: customAnimation,
            mode: 'md'
        }),
        AppRoutingModule,
        KwLoadingDirective,
        HasScrollbarDirective,
        LifecycleDirective,
        FontAwesomeModule,
        ToastModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            defaultLanguage: 'it'
        }),
    ],
    providers: [
        provideAnimations(),
        provideHttpClient(
            withInterceptors([
                errorInterceptor,
                authInterceptor
            ])
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: KW_STORAGE_PREFIX, useValue: 'csm_storage' },
        { provide: KW_SESSION_PREFIX, useValue: 'csm_storage' },
        { provide: LOCALE_ID, useValue: 'it-IT' }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(private faLibrary: FaIconLibrary,
                private faConfig: FaConfig) {
        this.faLibrary.addIconPacks(fas, fad);
        this.faConfig.defaultPrefix = 'fad';
    }
}
